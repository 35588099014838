<template>
<div class="table-add" v-loading.fullscreen.lock="loading">
    <div class="table-add-header">
        <h1>Создание акции</h1>
        <div class="flex mt-20">
            <button class="table-lang-button" type="button" @click="langName='ua'" :class="langName=='ua'?'active':''">UA</button>
            <button class="table-lang-button ml-10" type="button" @click="langName='en'" :class="langName=='en'?'active':''">ENG</button>
        </div>
        <div class="add-inputs" style="max-width:600px;">
            <el-form ref="stockForm" :model="Stock" :rules="StockRules">
                <div class="form-au" v-show="langName=='ua'">
                    <div class="item">
                        <el-form-item>
                            <el-checkbox size="medium" v-model="Stock.status" class="mt-20"> Активна</el-checkbox>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p> Короткое название</p>
                        <el-form-item prop="title_ua">
                            <el-input v-model="Stock.title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p> Полное название акции</p>
                        <el-form-item prop="description_ua"> 
                            <el-input type="textarea" v-model="Stock.description_ua" placeholder="Please input" maxlength="160"  show-word-limit> </el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p> Тело акции</p>
                        <el-form-item prop="text_ua"> 
                            <RichText   v-model="Stock.text_ua" />
                        </el-form-item>
                    </div> 
                    <div class="item">
                        <p>Действие акции</p>
                        <div class="flex w-full items-center">
                            <el-form-item prop="start_date">
                                <el-date-picker class="w-full" v-model="Stock.start_date" format="yyyy/MM/dd" value-format="yyyy-MM-dd" type="date" placeholder="satart day">
                                </el-date-picker>
                            </el-form-item>
                            <div class="date-line"></div>
                            <el-form-item prop="end_date">
                                <el-date-picker class="w-full" v-model="Stock.end_date" format="yyyy/MM/dd" value-format="yyyy-MM-dd" type="date" placeholder="end day">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="item">
                        <el-form-item>
                            <div class="upload-image">
                                <button class="bg-red">
                                    Upload image
                                    <input type="file" name="image" @change="uploadPgoto" accept="image/png, image/gif, image/jpeg">
                                </button>
                                <p :class="erros.image?'c-red':''"> {{Stock.image.name? Stock.image.name:'upload image'}} </p>
                            </div>
                            <p v-if="erros.image" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>
                </div>
                <div class="form-en mt-20" v-show="langName=='en'">
                    <div class="item">
                        <p> Короткое название</p>
                        <el-form-item prop="title_en">
                            <el-input v-model="Stock.title_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p> Полное название акции</p>
                        <el-form-item prop="description_en">
                            <el-input type="textarea" v-model="Stock.description_en" placeholder="Please input" maxlength="160"  show-word-limit> </el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p> Тело акции</p>
                        <el-form-item prop="text_en"> 
                            <RichText  v-model="Stock.text_en" />
                        </el-form-item>
                    </div> 
                </div>

                <div class="save-buttons">
                    <button class="button" type="button" @click="$router.push('/admin')"> {{$t('back')}} </button>
                    <button class="button save ml-10" type="button" @click="PostStock()"> Создать</button>
                </div>
            </el-form>
        </div>

    </div>
</div>
</template>

<script>
import RichText from '../../../components/RichText/index.vue'
export default {
    components:{
        RichText
    },
    data() {
        return {
            checked: false,
            langName: 'ua',
            fileList: [],
            value1: '',
            form: {
                name: ''
            },
            loading:false,
            erros:{
                image:false,
                en:false,
                ua:false
            },
            Stock: {
                title_ua: '',
                title_en: '',
                description_ua: '',
                description_en: '',
                text_ua: '',
                text_en: '',
                start_date: '',
                end_date: '',
                status: '',
                image: ''
            },
            StockRules: {
                title_ua: [{
                        required: true,
                        message: 'Please input Activity name',
                        trigger: 'blur'
                    },
                    {
                        min: 4,
                        max:80,
                        message: 'Length should be 4 to 80',
                        trigger: 'blur'
                    }
                ],
                title_en: [{
                        required: true,
                        message: 'Please input Activity name',
                        trigger: 'blur'
                    },
                    {
                        min: 4,
                        max:80,
                        message: 'Length should be 4 to 80',
                        trigger: 'blur'
                    }
                ],
                description_ua: [{
                        required: true,
                        message: 'Please input Activity name',
                        trigger: 'blur'
                    },
                    {
                        min: 4,
                        max:160,
                        message: 'Length should be 4  to 160 ',
                        trigger: 'blur'
                    }
                ],
                description_en: [{
                        required: true,
                        message: 'Please input Activity name',
                        trigger: 'blur'
                    },
                   {
                        min: 4,
                        max:160,
                        message: 'Length should be 4  to 160',
                        trigger: 'blur'
                    }
                ],
                text_ua: [{
                        required: true,
                        message: 'Please input Activity name',
                        trigger: 'blur'
                    },
                    {
                        min: 20, 
                        message: 'Length should be 20 to 2500',
                        trigger: 'blur'
                    }
                ],
                text_en: [{
                        required: true,
                        message: 'Please input Activity name',
                        trigger: 'blur'
                    },
                    {
                        min: 20, 
                        message: 'Length should be 20 to 2500',
                        trigger: 'blur'
                    }
                ],
                start_date: [{
                    required: true,
                    message: 'Please input Activity name',
                    trigger: 'blur'
                }, ],
                end_date: [{
                    required: true,
                    message: 'Please input Activity name',
                    trigger: 'blur'
                }, ],
                status: '',
            },
            Files:[],
            file:'',
            disabled:true,
            test:{},
            fileLoad:false
        }

    },
    watch:{
        'Stock.image'(){
            if(this.Stock.image){ this.erros.image=false }
        }
    },
    methods: {
        uploadPgoto(event) {
            this.Stock.image = event.target.files[0];

        },
        PostStock() {
            this.$refs.stockForm.validate((valid) => { 
                if (valid && this.Stock.image) {
                     if (this.Stock.status) {
                        this.Stock.status = 1
                    } else {
                        this.Stock.status = 0
                    }
                    this.loading=true
                    let data = new FormData();
                    for (let i of Object.keys(this.Stock)) {
                        data.append(i, this.Stock[i]);
                    }

                   
                    this.axios.post('/admin/stock', data).then(res => {
                        console.log(res)
                        this.$router.push('/admin')
                        this.$notify({
                            title: 'Успешный',
                            message: 'Информация успешно сохранена',
                            type: 'success'
                        });
                     })
                     .catch((e)=>{
                         console.log(e)
                         this.$notify.error({
                                title: 'Error',
                                message: 'Помилка входу або пароля'
                            });
                     })
                     .finally(()=>{
                         this.loading=false
                     })
                }
                else{ 
                    var title_ua = this.Stock.title_ua=='' || this.Stock.title_ua.length<4 || this.Stock.title_ua.length>80? true : false
                    var description_ua = this.Stock.description_ua=='' || this.Stock.description_ua.length<4 || this.Stock.description_ua.length>160? true : false 
                    var text_ua = this.Stock.text_ua=='' || this.Stock.text_ua.length<20 || this.Stock.text_ua.length>2500? true : false 

                    var title_en = this.Stock.title_en=='' || this.Stock.title_en.length<4 || this.Stock.title_en.length>80? true : false
                    var description_en = this.Stock.description_en=='' || this.Stock.description_en.length<4 || this.Stock.description_en.length>160? true : false 
                    var text_en= this.Stock.text_en=='' || this.Stock.text_en.length<20 || this.Stock.text_en.length>2500? true : false 

                    if(!this.Stock.image || title_ua  || description_ua   || text_ua || this.Stock.start_date=='' || this.Stock.end_date==''){
                        this.erros.ua=true
                         if(!this.Stock.image){ this.erros.image=true }
                         if(this.langName!='ua'){ this.langName="ua"}
                    }
                    else{
                        if(title_en || description_en || text_en){
                            this.erros.en=true
                            this.langName="en"
                        } 

                    }
                }

            })

        },
        UploadFile(event){
             this.file= event.target.files[0]
             this.disabled=false
        },
        SaveFile(){
            let data = new FormData();
            data.append('file', this.file)
            this.fileLoad=true
            this.axios.post('/admin/upload', data)
            .then(res=>{
                var link= `https://avior.prestigeautofl.com${res.data.data}` 
                this.Files.push(link)
                this.file=''
                this.fileLoad=false
            })
            .catch(()=>{ this.loading=false })
        },
        Copy(item){
            var a=this.$copyText(item) 
            a ? this.$notify({ title: 'Link Copied', type: 'success' }) : this.$notify({ title: 'Not Copied', type: 'success' })
        }
    }
}
</script>

<style scoped lang="scss">
.date-line {
    width: 30px;
    height: 3px;
    background: rgba(69, 72, 81, 0.8);
    border-radius: 5px;
    margin: 0px 10px;
}

.files-list{
    margin-bottom: 30px;
    .files-list-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0px;
        border-bottom: 1px solid rgb(151, 151, 151);
        .copy{
            padding: 10px;
            background: black;
            color: #fff;
            border-radius: 5px;
            border: none;
            cursor: pointer;
        }
        p{
            overflow: hidden;
            width:400px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }
    .file-upload-buttons{
        display: flex;
        align-items: center;
        .file-upload-buttons-upload{
            padding: 10px;
            width: 120px;
            position: relative;
            overflow: hidden;
            background: black;
            color: #fff;
            margin-right: 10px;
            border: none;
            border-radius: 5px;
            input{
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                left: 0;
                right: 0;
            }
        }
        .file-upload-buttons-save{
            padding: 10px;
            width: 120px;
            position: relative;
            overflow: hidden;
            background: #409EFF;
            color: #fff;
            margin-right: 10px;
            border: none;
            border-radius: 5px;
            opacity: 0.5;
            &.active{
                opacity: 1;
            }

        }
    }

}


.save-buttons {
    padding-top: 20px;
}
.upload-image{
    width: 100%;
    display: flex;
    align-items: center;
    p{
        margin-left: 10px;
    }
    button{
        width: 200px;
        position: relative;
        overflow: hidden;
        padding: 10px 15px;
        border-radius: 5px;
        color: var(--white-color);
        font-family: Stem-Medium;
        font-size: 1rem;
        line-height: 1.125rem;
        border: none;
        color: var(--black);
        input{
            position: absolute;
            width: 100%;
            height: 100%;
            top:0;
            left: 0;
            opacity: 0;
            z-index: 1;
            }
    }
}
.error-text{
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
}

.c-red{
    color: red !important;
}
 
</style>
